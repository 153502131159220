import React from "react";
import { Script } from "gatsby"

const GoogleReview = () => {
    return (
        <div className="google-review-wrapper d-md-flex align-items-center">
            <Script src="https://apps.elfsight.com/p/platform.js" strategy="idle" />
            <div className="elfsight-app-bca3669b-c500-48ea-a6c4-c5e32338bce2" data-elfsight-app-lazy></div>
        </div>
    )

}

export default GoogleReview